
.file-link-card__content {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
}

.file-link-card__link-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    margin-right: 10px;
}

a.file-link-card__link-container .entity-title__header {
    color: var(--thm-dark);
}

a.file-link-card__link-container .entity-title__sub-header {
    color: var(--thm-dark-75);
}

.file-link-card__share-icon-container {
    padding: 10px;
    margin: auto 10px auto 0;
}

.file-link-card__remove-button {
    display: flex;
    width: 50px;
    padding: 10px;
    margin: auto 0;
    cursor: pointer;
    justify-content: flex-end;
}