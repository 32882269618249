.add-feature__container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: .5em;
    margin: 0 1em 0 0;
    cursor: pointer;
    color: var(--thm-primary);
}

.add-feature__title {
    margin: 0 0 0 1em;
}