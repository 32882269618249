.person-bulk-add__add-name-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 5px;
}

.person-bulk-add__fa-icon {
  color: var(--thm-primary);
}
