.main-nav__container {
    /* here we need to do some responsive media queries */
    display: flex;
    flex-direction: column;
    width: 10em;
    min-width: 10em;
    height: 100vh;
    border-right: .2em solid #EEE;
}

.main-nav__container.sandbox {
    background-color: rgb(200, 210, 255);
}

.main-nav__image-icon {
    margin: 1em;
}

.main-nav__logo {
    display: block;
    margin: auto;
    width: 100%;
}