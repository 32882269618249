.tab-list-container {
  min-height: 70vh;
  max-height: 70vh;
  display: flex;
  flex-direction: column;
}

.tab-list-header-container {
  padding: 10px;
  padding-top: 30px;
  background-color: var(--thm-dark-alt);
  display: flex;
  justify-content: center;
}

.tab-list-input {
  background-color: var(--thm-dark-alt);
  width: 90%;
  border: 1px solid #8f8f8f;
  height: 40px;
  padding: 10px;
  font-family: "Noto Sans", sans-serif, Arial;
  color: white;
  position: relative;
}

.tab-list-icon-container {
  position: absolute;
  right: 30px;
  margin-top: 7px;
}

.tab-list-list-container {
  display: flex;
  width: 100%;
  background-color: var(--thm-dark-alt);
  color: var(--thm-dark-5);
  list-style-type: none;
  padding: 0 10px;
  justify-content: flex-start;
}

.tab-list-tab {
  text-transform: uppercase;
  padding: 10px;
  font-size: 0.875em;
  font-weight: bold;
  cursor: pointer;
}

.tab-list-list-item-container {
  overflow-y: scroll;
  padding: 0 10px;
}
