.bulk-add-entity-list-item {
  display: flex;
  padding: 8px 0 8px 0;
  border-top: 2px solid var(--thm-dark-25);
}

.bulk-add-entity-list-item:last-child {
  border-bottom: 2px solid var(--thm-dark-25);
}

.bulk-add-entity__button-container {
  width: 100%;
  margin: 20px 0 10px 0;
  display: flex;
  justify-content: space-around;
}

.bulk-add-entity__list-container {
  padding-inline-start: 0;
  padding: 0 10%;
  max-height: 500px;
  overflow: scroll;
}

.bulk-add-entity__header-text {
  margin-top: 25px;
  padding: 0 10%;
}
