.project-card--low:before {
    content: "";
    position: absolute;
    background-color: var(--thm-alert-green);
    top: 0;
    left: 1.2em;
    width: .8em;
    height: 1.2em;
}

.project-card--medium:before {
    content: "";
    position: absolute;
    background-color: var(--thm-alert-yellow);
    top: 0;
    left: 1.2em;
    width: .8em;
    height: 1.2em;
}

.project-card--high:before {
    content: "";
    position: absolute;
    background-color: var(--thm-alert-orange);
    top: 0;
    left: 1.2em;
    width: .8em;
    height: 1.2em;
}

.project-card--urgent:before {
    content: "";
    position: absolute;
    background-color: var(--thm-alert-red);
    top: 0;
    left: 1.2em;
    width: .8em;
    height: 1.2em;
}