.filter-list__container {
  width: 250px;
  min-width: 250px;
  padding: var(--spacing-default) var(--spacing-default) var(--spacing-default) 0;
  margin: var(--spacing-default) var(--spacing-default) var(--spacing-default) auto;
}

.entity-list__card-container > .filter-list__container {
  width: 250px;
  min-width: 250px;
}

.filter-header__container {
  align-items: center;
}

.filter-header__heading {
  margin: auto auto 0 0;
}

.filter-header__clear-all {
  color: var(--thm-primary);
  margin: auto 10px auto auto;
  cursor: pointer;
}

.filter-header__clear-all:hover {
  color: var(--thm-primary-50);
  font-weight: 900;
}

/** Filter item */
.filter-item__option-container {
  margin: auto auto auto 10px;
  align-items: center;
}

.filter-item__checkbox {
  cursor: pointer;
  margin-right: 0;
}

.filter-item__checkbox:disabled {
  cursor: default;
}

.filter-item__option-label {
  display: block;
  line-height: 2.8em;
  width: 12em;
  margin-right: .7em;
  padding-left: var(--spacing-default);
  cursor: pointer;
}

.filter-item__option-label.disabled {
  color: var(--thm-dark-50);
  cursor: default;
}

