.circle-image__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: .1em 2em .1em .7em;
}

.circle {
    border-radius: 50%;
}

.circle-image__image {
    display: block;
    width: 100%;
    height: 100%;
}

.circle-image__fallback {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFF;
    width: 100%;
    height: 100%;
    background-color: var(--thm-primary);
}

.circle-image__fallback-text {
    display: block;
    color: #FFF;
    margin: 0;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
}