@import url(https://fonts.googleapis.com/css?family=Noto+Sans);
@import url(https://fonts.googleapis.com/css?family=Noto+Serif+SC);

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

:root {
    /* Theme colors */
    --thm-dark: rgb(4, 26, 38);
    --thm-dark-75: rgb(67, 84, 93);
    --thm-dark-50: rgb(129, 140, 146);
    --thm-dark-25: rgb(192, 198, 201);
    --thm-dark-5: rgb(242, 243, 244);
    --thm-dark-15-percent: rgba(67, 84, 93, 0.15);
    --thm-dark-20-percent: rgba(67, 84, 93, 0.2);
    --thm-dark-alt: rgb(9, 26, 37);
    --thm-primary: rgb(0, 117, 255);
    --thm-primary-75: rgb(64, 152, 255);
    --thm-primary-50: rgb(127, 186, 255);
    --thm-primary-25: rgb(191, 220, 255);
    --thm-white: #FFF;
    --thm-alert-green: rgb(83, 254, 154);
    --thm-alert-yellow: rgb(254, 217, 84);
    --thm-alert-orange: rgb(244, 177, 100);
    --thm-alert-red: rgb(254, 96, 84);

    /* Text colors */
    --txt-color-dark: var(--thm-black);
    --txt-color-dark-2: var(--thm-dark-75);
    --txt-color-medium: var(--thm-dark-50);
    --txt-color-medium-2: var(--thm-dark-25);
    --txt-color-light: var(--thm-dark-5);
    --txt-color-bright: var(--thm-white);

    --spacing-default: .6rem;
    --spacing-2: 1.2rem;
    --spacing-card: .9rem;

    /* font sizes */
    --font-h1: 3.125rem;
    /* 50px */
    --font-h2: 1.875rem;
    /* 30px */
    --font-h3: 1.25rem;
    /* 20px */
    --font-default: 1rem;
    /* 16px */
    --font-eyebrow: .75rem;
    /* 12px */
    --font-button: .875rem;
    /* 14px */
    --font-list-type: .875rem;
    /* 14px */

    --font-family-default: 'Noto Sans', sans-serif, Arial;
    --font-family-body: 'Noto Serif SC', 'Times New Roman', Times serif;
}


@media (max-width: 950px) {

    :root {

        --spacing-default: .6rem;
        --spacing-card: .9rem;

        /* font sizes */
        --font-h1: 1.8rem;
        /* 50px */
        --font-h2: 1.5rem;
        /* 30px */
        --font-h3: 1.2rem;
        /* 20px */
        --font-default: 1rem;
        /* 16px */
        --font-eyebrow: .75rem;
        /* 12px */
        --font-button: .875rem;
        /* 14px */
        --font-list-type: .875rem;
        /* 14px */
    }
}

input,
button {
    outline: none;
}

.main__layout {
    width: 100vw;
    height: 100vh;
    display: flex;
}

.main__content {
    display: flex;
    flex: 1;
}

.image {
    display: block;
    width: 100%;
}

.flx {
    display: flex;
}

.flx--one {
    flex: 1;
}

.flx--column {
    flex-direction: column;
}

.flx--space-around {
    justify-content: space-around;
}

.flx--space-between {
    justify-content: space-between;
}

.flx--row {
    flex-direction: row;
}

.flx--end {
    justify-content: flex-end;
    align-content: flex-end;
}

.flx--start {
    justify-content: flex-start;
    align-content: flex-start;
}

.flx--center {
    justify-content: center;
    align-items:  center;
}

/* https://css-tricks.com/dont-overthink-flexbox-grids/ && https://codepen.io/imohkay/pen/gpard */

.flx--grid {
    display: flex;
    flex-wrap: wrap;
}

.flx--grid.grid--margin {
    padding: 0 var(--spacing-card) var(--spacing-card) 0;
}

.col {
    flex: 1;
}

.one--col>* {
    width: 100%;
    max-width: 100%;
}

.two--col>* {
    width: 50%;
    max-width: 50%;
}

.three--col>* {
    width: 33%;
}

.grid--margin>* {
    margin: var(--spacing-card) 0 0 var(--spacing-card);
}

.grid--margin.two--col>* {
    margin: var(--spacing-card) 0 0 var(--spacing-card);
    width: calc(50% - var(--spacing-card));
    min-width: calc(50% - var(--spacing-card));
    max-width: calc(50% - var(--spacing-card));
}

.grid--margin.three--col>* {
    margin: var(--spacing-card) 0 0 var(--spacing-card);
    width: calc(33% - var(--spacing-card-thirds));
    min-width: calc(33% - var(--spacing-card-thirds));
    max-width: calc(33% - var(--spacing-card-thirds));
}

@media (max-width: 950px) {
    .flx--grid {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .two--col>* {
        flex: 1;
        width: 100%;
        max-width: 100%;
    }

    .three--col>* {
        flex: 1;
        width: 100%;
        max-width: 100%;
    }

    .grid--margin.two--col>* {
        margin: var(--spacing-card) 0 0 var(--spacing-card);
        width: calc(100% - var(--spacing-card));
        min-width: calc(100% - var(--spacing-card));
        max-width: calc(100% - var(--spacing-card));
    }

    .grid--margin.three--col>* {
        margin: var(--spacing-card) 0 0 var(--spacing-card);
        width: calc(100% - var(--spacing-card));
        min-width: calc(100% - var(--spacing-card));
        max-width: calc(100% - var(--spacing-card));
    }

}

.width--100 {
    width: 100%;
}

.with--position-relative {
    position: relative;
}

.with--pointer {
    cursor: pointer;
}

.with--scroll {
    flex: 1 1 auto;
    overflow-y: auto;
    min-height: 0px;
}

.with--border-top {
    border-top: 1px solid var(--thm-dark-20-percent);
}

.with--no-border {
    border: none;
}

.with--no-scroll {
    overflow: hidden;
}

.with--margin-0 {
    margin: 0;
}

.with--margin-1 {
    margin: var(--spacing-default);
}

.with--margin-2 {
    margin: calc(var(--spacing-default) * 2);
}

.with--margin-3 {
    margin: calc(var(--spacing-default) * 3);
}

.with--v-margin-0 {
    margin-top: 0;
    margin-bottom: 0;
}

.with--v-margin-1 {
    margin-top: var(--spacing-default);
    margin-bottom: var(--spacing-default);
}

.with--v-margin-2 {
    margin-top: calc(var(--spacing-default) * 2);
    margin-bottom: calc(var(--spacing-default) * 2);
}

.with--h-margin-0 {
    margin-left: 0;
    margin-right: 0;
}

.with--h-margin-1 {
    margin-left: var(--spacing-default);
    margin-right: var(--spacing-default);
}

.with--h-margin-2 {
    margin-left: calc(var(--spacing-default) * 2);
    margin-right: calc(var(--spacing-default) * 2);
}

.with--l-margin-0 {
    margin-left: 0;
}

.with--l-margin-1 {
    margin-left: var(--spacing-default);
}

.with--l-margin-2 {
    margin-left: calc(var(--spacing-default) * 2);
}

.with--r-margin-0 {
    margin-right: 0;
}

.with--r-margin-1 {
    margin-right: var(--spacing-default);
}

.with--r-margin-2 {
    margin-right: calc(var(--spacing-default) * 2);
}

.with--b-margin-0 {
    margin-bottom: 0;
}

.with--b-margin-1 {
    margin-bottom: var(--spacing-default);
}

.with--b-margin-2 {
    margin-bottom: calc(var(--spacing-default) * 2);
}

.with--t-margin-1 {
    margin-top: var(--spacing-default);
}

.with--t-margin-2 {
    margin-top: calc(var(--spacing-default) * 2);
}

.with--t-margin-3 {
    margin-top: calc(var(--spacing-default) * 3);
}

.with--v-margin-auto {
    margin-top: auto;
    margin-bottom: auto;
}

.with--h-margin-auto {
    margin-left: auto;
    margin-right: auto;
}

.with--margin-auto {
    margin: auto;
}

.with--padding-0 {
    padding: 0;
}

.with--padding-1 {
    padding: var(--spacing-default);
}

.with--padding-2 {
    padding: calc(var(--spacing-default) * 2);
}

.hidden {
    display: none;
}