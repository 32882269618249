.full-screen__overlay {
    background-color: rgba(180, 180, 180, .6);
    width: 100vw;
    height: 100vh;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
}

.add-card__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: auto;
    align-items: center;
    justify-content: center;
}


.add-button {
    font-size: 3em;
    color: #0075FF;
}