.entity-title__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-width: calc(100vw - 10em);
    background-color: var(--thm-dark-alt);
    color: var(--thm-white);
    padding: 1.5em 1em 0em 1.5em;
}

.entity-title__sub-header {
    margin: .2em 0 .2em 0;
}

.entity-title__header {
    margin: 0;
    cursor: pointer;
}

.entity-title__tabs {
    display: flex;
    flex-direction: row;
    /* The following would be helpful in mobile, but it breaks the way we show selection. */
    /* overflow: auto; */
    max-width: 100%;
}

.entity-title__tab {
    display: block;
    margin: 1em 1.2em 0 0;
    text-decoration: none;
}

.entity-title__tab.active {
    margin-bottom: -.65em;
    border-bottom: .7em solid var(--thm-primary);
}

.entity-title__icon {
    margin: auto .5em;
    width: 1.5em;
    height: 1.5em;
}