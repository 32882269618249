.select-box--container {
  height: 30px;
  border: 1px solid #aaa;
  width: 100%;
}

.select-box--box {
  width: 100%;
  position: absolute;
  left: 15px;
}

.select-box--selected-item {
  display: inline-block;
  width: 100%;
  padding: 4px 12px;
  vertical-align: middle;
}

.select-box--items div {
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  padding: 6px;
  padding-left: 20px;
}

.select-box--arrow {
  width: 30px;
  height: 30px;
  display: inline-block;
  background: #aaaaaa;
  position: absolute;
}

.select-box--arrow-down {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 10px solid #0075FF;
}

.select-box--arrow-up {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid #0075FF;
}

.select {
  flex-grow: 1;
}

.select > .select__control {
  display: flex;
  font-size: var(--font-default);
  border: 1px solid var(--thm-dark-75);
  border-radius: 0;
}
.select > .select__control:hover {
  font-size: var(--font-default);
  border: 1px solid var(--thm-dark-75);
  border-radius: 0;
}
