
.task-card__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.task-card__remove-button {
  padding: 0 10px;
  margin: auto 0 auto 30px;
  cursor: pointer;
}

.task-card__content .entity-title__header {
  line-height: 1.5;
}

.task-card__assigned-link {
  margin: 0;
  color: var(--thm-primary);
  text-decoration: none;
  font-weight: bold;
  line-height: 1.5;
}

 /* txt with--margin-0 with--bold-weight with--color-primary with--no-decoration */