.hdg {
  font-weight: bold;
  color: var(--txt-color-dark);
  font-family: var(--font-family-default);
  letter-spacing: .75px;
  margin-top: 0;
}

.txt {
  color: var(--txt-color-dark);
  margin-top: 0;
}

.txt--color-dark-2 {
  color: var(--txt-color-dark-2);
}

.txt--color-dark {
  color: var(--txt-color-dark);
}

.txt--medium {
  color: var(--txt-color-medium);
}

.txt--medium-2 {
  color: var(--txt-color-medium-2);
}

.txt--light {
  color: var(--txt-color-light);
}

.txt--font-wt-normal {
  font-weight: normal;
  letter-spacing: .5px;
}

.hdg--headline-1 {
  font-size: var(--font-h1);
}

.hdg--headline-2 {
  font-size: var(--font-h2);
}

.hdg--headline-3 {
  font-size: var(--font-h3);
}

.hdg--subhead {
  font-size: var(--font-default);
}

.hdg--eyebrow {
  font-size: var(--font-eyebrow);
  text-transform: uppercase;
}

.hdg--button-txt {
  font-size: var(--font-button);
  text-transform: uppercase;
}

.txt--list-type {
  font-size: var(--font-list-type);
  font-family: var(--font-family-default);
}

.txt--body-copy {
  font-weight: 500;
  font-size: var(--font-default);
  font-family: var(--font-family-body);
}

/** Modifiers for text */
.with--normal-weight {
  font-weight: normal;
}

.with--color-primary {
  color: var(--thm-primary);
}

.with--no-decoration {
  text-decoration: none;
}

.with--bold-weight {
  font-weight: bold;
}

.with--default-font-size {
  font-family: var(--font-family-default);
  font-size: var(--font-default);
}

.txt--align-center {
  text-align: center;
}
