.main-nav-item__container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.main-nav-item__link {
    display: flex;
    max-height: 2em;
    color: var(--txt-color-dark);
    text-decoration: none;
    text-transform: uppercase;
    margin: .5em .5em .5em 1em;
}

.main-nav-item__link__icon {
    margin: auto .5em;
    width: 1.5em;
    height: 1.5em;
}

.main-nav-item__link.active {
    padding-left: .3em;
    margin-left: 0;
    color: var(--thm-primary);
    border-left: solid .7em var(--thm-primary);
}