input:invalid {
  border-color: var(--thm-alert-red);
}

input,
input:valid {
  border-color: var(--thm-dark-75);
}

input[type="checkbox"] {
  vertical-align: middle;
  cursor: pointer;
}


input[type="checkbox"] {
    -webkit-appearance: none;
    width: 21px;
    height: 21px;
    background: var(--thm-white);
    border-radius: 3px;
    border: 1px solid var(--thm-dark-75);
}

input[type="checkbox"]:checked {
    box-shadow: inset 0 0 0 var(--thm-white);
    background: var(--thm-primary);
    background-repeat: no-repeat;
    background-position: 4px 4px;
    background-size: .9em;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAUCAYAAAH+e/2mAAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAFqADAAQAAAABAAAAFAAAAABFKUIAAAABjUlEQVQ4Ea2TQShEURSGxxRq1BQpilIWY8fK0taCsra01mztZCtbWyk7xcKW3WShlMKGDQszG0qhFEn4zp1zXvfeee+ZjFu/e85/vvPfaTSFQtr55jg/KYzCqNtkxooT3JI0Xc7x/uj+boFiVRupm+kemJTM5pPGCtkIssVgWHRZ1IPoDZ3ahg2yH0pILdjuRe7Es6CHqCn34QY052pUfFI9uWZ9Xz7bvjfcsTqA4gboRsFqPGu7J6CB5n5dALrQ15r/76wNoAUF879roKKB3NNZgc4H+FT4MAHVeEgMCrwD9b98XwZ2rmVAM2kGd3cM+5+t5oGLAWgNQJ8HSelesXnLDTBsCy3D/zR4ZAK92mPcjY7zCdn0Aq1c+nMwCSPo0ZL0vuUudxK6FgVKuxIEYlTQHpLfYPCDDUAa5gOojvxzTzMUswI/+xT1HRqNQbzliJN2I+aCHmArZekMr4xK6Cqav9CPByFZDWAPOooC0trtrIxcn6R+dJmS+I43lbvczpCQMXSMntB6Ozs+8wPflm7W9zzGnwAAAABJRU5ErkJggg==")
}

input[type="checkbox"]:disabled {
    box-shadow: inset 0 0 0 var(--thm-white);
    border: 1px solid var(--thm-dark-50);
    background: var(--thm-dark-15-percent);
}


.input__container {
  margin: var(--spacing-default) 0;
}

.input__label-title {
  margin: 0 .4em 0 0;
  line-height: 1.5em;
}

.input__label--editing {
  display: block;
  line-height: 2.8em;
  width: 12em;
  margin-right: .7em;
}

.input__viewing-value {
  color: var(--thm-dark-75);
}

.input {
  display: block;
  font-size: var(--font-default);
  padding: var(--spacing-default);
  flex-grow: 1;
  border: 1px solid var(--thm-dark-75);
}