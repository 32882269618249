.accordion__button {
    margin: 10px 0;
    height: 50px;
    background-color: transparent;
    border: none;
    border-top: solid 1px var(--thm-dark-25);
    border-bottom: solid 1px var(--thm-dark-25);
    cursor: pointer;
}

.accordion__title {
    font-weight: 600;
    margin: auto auto auto 10px;
    width: max-content;
}

.accordion__expansion-icon {
    background: transparent;
    color: var(--thm-primary);
    border: none;
    font-size: var(--font-button);
    margin: auto 5px auto auto;
}

.accordion__content.expanded {
    display: block;
}

.accordion__content.closed {
    display: none;
}
