.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }

  /* input[type="file"] {
    opacity: 0;
    position: absolute;
    pointer-events: none;
    width: 1px;
    height: 1px;
    color: black;
  } */

  input[type="file"] + label {
    height: 100%;
    color: #f1e5e6;
    background-color: #d3394c;
    display: inline-block;
  }

  input[type="file"] + label:hover {
    background-color: #722040;
}

/* .upload-btn-wrapper > input[type="file"] {
    font-size: 200px;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
} */