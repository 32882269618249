.entity-list__container {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: var(--thm-dark-5);
}

.entity-list__card-container {
  display: flex;
  padding: var(--spacing-default);
  overflow-y: auto;
  width: 100%;
}

.entity-list__card-container > .flx--column {
  width: 100%;
}

.waiting-text {
  padding: 30px;
}

.toaster-container {
  background-color: rgb(0, 117, 255);
  padding: 3px 12px;
  color: white;
  position: fixed;
  top: 150px;
  right: 25px;
}
