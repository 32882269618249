
.textarea {
  display: flex;
  outline: none;
  font-size: var(--font-default);
  padding: var(--spacing-default);
  flex-grow: 1;
  width: 100%;
  height: 12em;
  border: 1px solid var(--thm-dark-75);
}