body {
  font-family: var(--font-family-default);
  /* Override amazon orange to fix branding */
  --button-background-color: var(--thm-primary);
  --link-color: var(--thm-primary-75);
  --lightSquidInk: var(--thm-alert-yellow);
  --button-click: var(--thm-primary-75);
  --color-primary-accent: var(--thm-dark-50);
  --color-primary: var(--thm-primary);
  --amazonOrange: var(--thm-primary);
  --lightAmazonOrange: var(--thm-primary-50);
  --darkAmazonOrange: var(--thm-primary);
  --ion-color-primary-tint: var(--thm-primary);
}

/* Override amazon defaults to fix branding */
body {

  /** Card layout */
  .Section__container___3YYTG {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: var(--thm-dark-5);
  }

  .Form__formContainer___1GA3x {
    position: relative;
    width: 320px;
    background-color: var(--thm-white);
    box-shadow: 2px 2px 12px 2px var(--thm-dark-20-percent);
    border: solid 1px var(--thm-dark-25);
    margin: auto;
  }

  .Form__formSection___1PPvW {
    display: flex;
    flex-direction: column;
  }
  /** -- end Card layout -- */

  /** Create an account */
  .Section__sectionBody___ihqqd {
    margin: 1.25em 1.25em 0 1.25em;
  }

  /** Header container */
  .Section__sectionHeader___2djyg {
    padding: 1.5em .25em 1em 1.25em;
    border-bottom: solid 1px var(--thm-dark-50);
  }
  /** Header content */
  .Section__sectionHeaderContent___1UCqa {
    font-weight: bold;
    color: var(--txt-color-dark);
    font-family: var(--font-family-default);
    font-size: var(--font-h3);
    letter-spacing: .75px;
  }

  /** Username input form field container */
  .Form__formField___38Ikl {
    display: flex;
    flex-direction: column;
  }


  /** Input label (not semantic label :( ) */
  .Input__inputLabel___3VF0S {
    font-weight: bold;
    color: var(--txt-color-dark);
    font-family: var(--font-family-default);
    letter-spacing: .75px;
    margin: .5em 0 .1em 0;
    font-size: var(--font-eyebrow);
    text-transform: uppercase;
  }
  /** Input */
  .Input__input___3e_bf {
    margin: var(--spacing-default) 0;
    border-radius: 0;
    display: block;
    font-size: var(--font-default);
    padding: var(--spacing-default);
    flex-grow: 1;
    width: 100%;
    border: 1px solid var(--thm-dark-75);
  }


  /** forgot your password? Container */
  .Hint__hint___2XngB {
    display: flex;
    flex-direction: column;
    margin: .5em 0;
    justify-content: space-between;
  }

  .Section__sectionFooterSecondaryContent___Nj41Q, .Hint__hint___2XngB {
    font-family: var(--font-family-default);
    font-size: var(--font-eyebrow); 
  }
  

  .Anchor__a___1_Iz8 {
    display: block;
    color: var(--thm-primary);
    // font-size: var(--font-eyebrow);  
    margin: .25em 0;
    text-decoration: underline;
    cursor: pointer;
  }

  .Anchor__a___1_Iz8:hover {
    color: var(--thm-primary-50);
  }

  .Button__button___vS7Mv {
    display: block;
    width: auto;
    border-radius: 3em;
    margin: .5em 0;
    padding: .5em 2em;
    outline: none;
    cursor: pointer;
    font-size: var(--font-button);
    color: var(--txt-color-light);
    background-color: var(--thm-primary);
    border: none;
  }

  .Section__sectionFooter___1T54C {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 0 1.25em 1.25em 1.25em;

  }
  div[data-test="sign-in-footer-section"] {
    .Section__sectionFooterSecondaryContent___Nj41Q {
      display: none;
    }
  }

  ul,
  ol,
  dl {
    margin: 0 auto;
    padding: 0;
  }
}
