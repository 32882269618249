.button {
  display: block;
  border-radius: 3em;
  margin: .5em;
  padding: .5em 2em;
  outline: none;
  cursor: pointer;
}

.button.primary {
  color: var(--txt-color-light);
  background-color: var(--thm-primary);
  border: none;
}

.button.primary:disabled {
  background-color: var(--thm-dark-50);
}

.button.secondary {
  color: var(--thm-primary);
  background-color: var(--thm-white);
  border: 1px solid var(--thm-primary);
}

.button.secondary:disabled {
  color: var(--thm-dark-50);
  border: 1px solid var(--thm-dark-50);
}