.add-entity__card {
    min-width: 25em;
}

.add-entity__button-container {
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.add-entity__button-container > .button {
    width: 11em;
}