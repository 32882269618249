.card__container {
    position: relative;
    background-color: var(--thm-white);
    box-shadow: 2px 2px 12px 2px var(--thm-dark-20-percent);
    border: solid 1px var(--thm-dark-25);
}

.card__inner-container {
    display: flex;
    flex-direction: column;
    margin: 1.2em;
}

.card__cancel-button {
    color: var(--thm-dark-50);
    font-size: 2em;
    padding: .5em;
    margin: .1em;
    line-height: .3em;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}

.card {
    flex-direction: row;
}