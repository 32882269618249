.size--32 {
  width: 32px;
  height: 32px;
}

.size--24 {
  width: 24px;
  height: 24px;
}

.size--20 {
  width: 20px;
  height: 20px;
}

.size--16 {
  width: 16px;
  height: 16px;
}

.size--12 {
  width: 12px;
  height: 12px;
}

.size--10 {
  width: 10px;
  height: 10px;
}

.size--8 {
  width: 8px;
  height: 8px;
}